@charset "UTF-8";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: "Bariol-Regular", sans-serif;
}

html, body, #root {
    height: 100%;
    width: 100%;
}

body {
    min-height: 100%;
}
