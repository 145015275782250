@charset "UTF-8";

@font-face {
    font-family: 'Bariol-Bold';
    src: local("Bariol Bold"), url('./fonts/bariol/Bariol-Bold.eot');
    src: url('./fonts/bariol/Bariol-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/bariol/Bariol-Bold.woff') format('woff'),
    url('./fonts/bariol/Bariol-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bariol-Regular';
    src: local("Bariol Regular"), url('./fonts/bariol/Bariol-Regular.eot');
    src: url('./fonts/bariol/Bariol-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/bariol/Bariol-Regular.woff') format('woff'),
    url('./fonts/bariol/Bariol-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

